



















































/*Checkbox Terms/Conditions*/
.custom-control {
  &.is-invalid > label:before{
    border-color: red;
  }
  padding-left: 2rem;
  .custom-control-input {
    width: 1.25rem;
    height: 1.25rem;
  }

  .custom-control-label {
    &::before, &::after, {
      top: 0;
      width: 1.25rem;
      height: 1.25rem;
      left: -2rem;
    }
  }
}
